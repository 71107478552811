import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import SignUp from './components/SignUp';
import SignIn from './components/SignIn';
import supabase from './components/SupabaseClient';
import WelcomePage from './components/Home';
import UpdatePassword from './components/UpdatePW';
import ConfirmedEmail from './components/ConfirmedEmail';
import Plans from './components/Plans';
import Dashboard from './components/Dashboard'
import PaymentAccepted from './components/PaymentAccepted';
import TermsOfService from './components/Terms';
import PrivacyPolicy from './components/Privacy';
import LogoGallery from './components/StoresList';
import SupportPage from './components/Support';
import HeaderChecker from './components/Origin';
import Blog from './components/Blog';

function App() {
 const [session, setSession] = useState(null);
 const [language, setLanguage] = useState('en');

 useEffect(() => {
  const { data: { subscription } } = supabase.auth.onAuthStateChange(
    async (event, session) => {
      setSession(session);
    }
  );

  return () => {
    subscription?.unsubscribe();
  };
}, []);

useEffect(() => {
  const userLang = navigator.language || navigator.userLanguage;

  if (userLang.startsWith('fr')) {
    setLanguage('fr'); // French
  } else if (userLang.startsWith('es')) {
    setLanguage('es'); // Spanish
  } else if (userLang.startsWith('de')) {
    setLanguage('de'); // German
  } else if (userLang.startsWith('lt')) {
    setLanguage('lt'); // Lithuanian
  } else {
    setLanguage('en'); // Default to English
  }
}, []);

 const userID = session?.user?.id;
 
 return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<WelcomePage userID={userID} language={language}/> } />
        <Route path="/plans" element={!session ? <Plans language={language}/> : <Navigate to="/" />}  />
        <Route path="/signin" element={!session ? <SignIn language={language}/> : <Navigate to="/" />} />
        <Route path="/signup/:productId?" element={!session ? <SignUp language={language}/> : <Navigate to="/" />}/>
        <Route path="/dashboard" element={<Dashboard language={language}/>} /> 
        <Route path="/updatepassword" element={<UpdatePassword language={language}/>} />
        <Route path="/confirmedemail/:productId?" element={<ConfirmedEmail language={language} />} />
        <Route path="/payment_accepted/:productId?" element={<PaymentAccepted />} />
        <Route path="/terms" element={<TermsOfService language={language}/>} />
        <Route path="/privacy" element={<PrivacyPolicy language={language}/>} />
        <Route path="/stores" element={<LogoGallery language={language}/>} />
        <Route path="/support" element={<SupportPage language={language}/>} />
        <Route path="/origin" element={<HeaderChecker />} />
        <Route path="/blog" element={<Blog language={language} />} />
      </Routes>
    </BrowserRouter>
 );
}

export default App;