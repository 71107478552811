import React from 'react';
import './Blog.css'; // Optional: Adjust the path if you need styling.
import Footer from './Footer';
import NavBar from './NavBar';

const Blog = ({ language }) => {
    return (
        <>
            <NavBar language={language} />
            <div className='blogMain'>
                <header>
                    <h1 className='capText redText'>
                        Step-by-Step Price Tracking Guides for Top Retailers
                    </h1>
                    <p>
                        Explore detailed guides to track prices effortlessly and shop smarter with Thryllo.
                    </p>
                </header>
                <section className="blogArticles">
                    <div>
                        <a
                            className="blogOtherLinks"
                            href="https://blog.thryllo.com/how-to-track-prices-on-amazon"
                            target='_blank' rel="noreferrer"
                        >
                            <article className="blogLinksThread">
                                <div className="blogCategory">Available Tracker</div>
                                <img src="https://blog.thryllo.com/assets/amazon-logo.svg" alt="Amazon Logo" />
                            </article>
                            How to Track Amazon Prices with Thryllo
                        </a>
                        <a
                            className="blogOtherLinks"
                            href="https://blog.thryllo.com/how-to-track-prices-on-walmart"
                            target="_blank" rel="noreferrer"
                        >
                            <article className="blogLinksThread">
                                <div className="blogCategory">Available Tracker</div>
                                <img src="https://blog.thryllo.com/assets/walmart-logo.svg" alt="Home Depot Logo" />
                            </article>
                            How to Track Walmart Prices with Thryllo
                        </a>
                        <a
                            className="blogOtherLinks"
                            href="https://blog.thryllo.com/how-to-track-prices-on-home-depot"
                            target="_blank" rel="noreferrer"
                        >
                            <article className="blogLinksThread">
                                <div className="blogCategory">Available Tracker</div>
                                <img src="https://blog.thryllo.com/assets/home-depot-logo.svg" alt="Home Depot Logo" />
                            </article>
                            How to Track Home Depot Prices with Thryllo
                        </a>
                        <a
                            className="blogOtherLinks"
                            href="https://blog.thryllo.com/how-to-track-prices-on-best-buy"
                            target="_blank" rel="noreferrer"
                        >
                            <article className="blogLinksThread">
                                <div className="blogCategory">Available Tracker</div>
                                <img src="https://blog.thryllo.com/assets/best-buy-logo.svg" alt="Best Buy Logo" />
                            </article>
                            How to Track Best Buy Prices with Thryllo
                        </a>
                        <a
                            className="blogOtherLinks"
                            href="https://blog.thryllo.com/how-to-track-prices-on-etsy"
                            target="_blank" rel="noreferrer"
                        >
                            <article className="blogLinksThread">
                                <div className="blogCategory">Available Tracker</div>
                                <img src="https://blog.thryllo.com/assets/etsy-logo.svg" alt="Etsy Logo" />
                            </article>
                            How to Track Etsy Prices with Thryllo
                        </a>
                        <a
                            className="blogOtherLinks"
                            href="https://blog.thryllo.com/how-to-track-prices-on-ebay"
                            target="_blank" rel="noreferrer"
                        >
                            <article className="blogLinksThread">
                                <div className="blogCategory">Available Tracker</div>
                                <img src="https://blog.thryllo.com/assets/ebay-logo.svg" alt="eBay Logo" />
                            </article>
                            How to Track eBay Prices with Thryllo
                        </a>
                    </div>
                </section>
            </div>
            <Footer language={language} />
        </>
    );
};

export default Blog;
